import { render, staticRenderFns } from "./OperatorPaymentsView.vue?vue&type=template&id=560c2a10&scoped=true&lang=pug"
import script from "./OperatorPaymentsView.vue?vue&type=script&lang=ts"
export * from "./OperatorPaymentsView.vue?vue&type=script&lang=ts"
import style0 from "./OperatorPaymentsView.vue?vue&type=style&index=0&id=560c2a10&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560c2a10",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VContainer,VRow,VTab,VTabItem,VTabsItems})
