





























import { mapActions } from 'vuex';
import { roundNumber } from '@/helpers/roundNumber';
import OperatorBalanceTokenListedMark from '@/views/OperatorBalanceView/OperatorBalanceTokenListedMark.vue';

export default {
  name: 'OperatorPaymentsCurrentBalance',
  components: {
    OperatorBalanceTokenListedMark
  },
  methods: {
    ...mapActions('OperatorPayments', ['setAddFundsModalState']),
    formatNumber(value: number): string {
      if (!+value) {
        return '0';
      }

      return roundNumber(value, 7);
    }
  }
};
