





























































import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import formatDate from '@/helpers/formatDate';
import { doDownload } from '@/helpers/download';
import { DateTime } from 'luxon';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { getOperatorInvoiceReportPdf } from '@/api/OperatorPayments';
import { updateSuperAdminInvoiceStatus } from '@/api/SuperAdmin';
import VStatus from '@/components/VStatus.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import VDataTableFooter from '@/components/v-data-table/VDataTableFooter.vue';
import { upperFirst, toLower } from 'lodash';

import {
  VuetifyTableOptions,
  OperatorInvoiceHistoryItem,
  OperatorInvoiceTypeNaming,
  EOperatorInvoiceStatus
} from '@/api/schema';
import type { AxiosError } from 'axios';
import { capitalization } from '@/helpers/formatString';

const defaultPageData = {
  page: 1,
  itemsPerPage: 10
};

const defaultDateData = {
  from: DateTime.utc()
    .startOf('month')
    .minus({ year: 1 })
    .toFormat('yyyy-LL'),
  to: DateTime.utc().endOf('month').toFormat('yyyy-LL')
};

const statusMap = {
  PENDING: {
    text: 'Pending',
    type: 'warning',
    value: EOperatorInvoiceStatus.PENDING
  },
  PAID: {
    text: 'Paid',
    type: 'success',
    value: EOperatorInvoiceStatus.PAID
  },
  NOT_PAID: {
    text: 'Not paid ',
    type: 'error',
    value: EOperatorInvoiceStatus.NOT_PAID
  },
  CANCELLED: {
    text: 'Cancelled',
    type: 'disabled',
    value: EOperatorInvoiceStatus.CANCELLED
  }
};

export default {
  name: 'OperatorBalanceInvoices',
  components: { VExtendedDataPicker, VStatus, VDataTableFooter },
  data(): any {
    return {
      statusMap,
      OperatorInvoiceTypeNaming,
      dates: { ...defaultDateData },
      pageData: { ...defaultPageData },
      total: 0,
      headers: [
        {
          text: 'Date',
          value: 'date',
          width: '17%'
        },
        {
          text: 'Type',
          value: 'type',
          width: '17%'
        },
        {
          text: 'Description',
          value: 'description',
          width: '17%'
        },
        {
          text: 'Amount',
          value: 'amount',
          width: '17%'
        },
        {
          text: 'Status',
          value: 'status',
          width: '16%'
        },
        {
          text: 'Download',
          value: 'download',
          width: '16%'
        }
      ],
      selectItems: Object.values(statusMap)
    };
  },
  computed: {
    ...mapState('OperatorPayments', ['loading', 'invoicesHistory']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['operatorId', 'envName']),
    requestData(): VuetifyTableOptions {
      return {
        ...this.pageData,
        from: DateTime.fromISO(this.dates.from)
          .startOf('month')
          .toFormat('yyyy-LL-dd'),
        to: DateTime.fromISO(this.dates.to)
          .endOf('month')
          .toFormat('yyyy-LL-dd')
      };
    },
    canUpdateInvoiceStatus(): boolean {
      return this.isSuperAdmin && this.$role.is('super-admin-finance');
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.pageData = { ...defaultPageData };
        }
      },
      immediate: true
    },
    pageData: {
      handler(): void {
        if (this.operatorId) {
          this.getInvoicesHistory();
        }
      }
    },
    dates: {
      handler(): void {
        if (this.operatorId) {
          this.getInvoicesHistory();
        }
      }
    }
  },
  methods: {
    ...mapMutations('OperatorPayments', ['setLoadingState']),
    ...mapActions('OperatorPayments', ['getOperatorInvoiceHistory']),
    formatDate,
    capitalization,
    async getInvoicesHistory(): Promise<void> {
      await this.getOperatorInvoiceHistory(this.requestData);
      this.total = this.invoicesHistory.totalElements;
    },
    async getReportPdf(data: OperatorInvoiceHistoryItem): Promise<void> {
      const typeFile = upperFirst(toLower(data.type));
      const brandName = this.envName?.replaceAll(' ', '_') || '';
      const dateFile = formatDate(data.date, 'yyyy.LL.dd');

      try {
        this.setLoadingState({ key: 'invoicesHistory', status: true });
        const res = await getOperatorInvoiceReportPdf(data.id);

        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/pdf'
          })
        );
        doDownload(url, `${typeFile}_Trueplay_${brandName}_${dateFile}.pdf`);
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.setLoadingState({ key: 'invoicesHistory', status: false });
      }
    },
    changeStatusHandler(
      data: OperatorInvoiceHistoryItem,
      newStatus: string
    ): void {
      updateSuperAdminInvoiceStatus(data.id, newStatus)
        .then(() => {
          this.$toast.success('Status updated');
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally(() => {
          this.getInvoicesHistory();
        });
    }
  }
};
