





























import { mapActions } from 'vuex';
import OperatorBalanceTokenListedMark from '@/views/OperatorBalanceView/OperatorBalanceTokenListedMark.vue';

export default {
  name: 'OperatorListingStatus',
  components: {
    OperatorBalanceTokenListedMark
  },
  methods: {
    ...mapActions('OperatorPayments', ['setAddFundsModalState'])
  }
};
