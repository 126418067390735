
































































































































import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import {
  getOperatorBalanceMovementReport,
  getOperatorBalanceMovementReportCsv
} from '@/api/OperatorPayments';
import {
  OperatorBalanceOverallData,
  OperatorBalanceOverallFeesItem,
  TransactionsTypeNaming,
  TransactionsTypes
} from '@/api/schema';
import formatDate from '@/helpers/formatDate';
import { doDownload } from '@/helpers/download';
import { errorToastMessage } from '@/helpers/errorToastMessage';

type OperationMapConfigItem = {
  label: string;
  income: 'positive' | 'negative';
  showOnFixedPrice?: boolean;
};

type FeeItem = OperatorBalanceOverallFeesItem & OperationMapConfigItem;

type OperationMapConfig = Partial<
  Record<TransactionsTypes, OperationMapConfigItem>
>;

const operationMapConfig: OperationMapConfig = {
  [TransactionsTypes.DEPOSIT]: {
    label: 'Deposits',
    income: 'positive',
    showOnFixedPrice: true
  },
  [TransactionsTypes.BETTING_REWARD]: {
    label: TransactionsTypeNaming[TransactionsTypes.BETTING_REWARD],
    income: 'positive',
    showOnFixedPrice: true
  },
  [TransactionsTypes.STAKING_REWARD]: {
    label: TransactionsTypeNaming[TransactionsTypes.STAKING_REWARD],
    income: 'positive',
    showOnFixedPrice: true
  },
  [TransactionsTypes.TRANSFER_IN]: {
    label: TransactionsTypeNaming[TransactionsTypes.TRANSFER_IN],
    income: 'positive',
    showOnFixedPrice: true
  },
  [TransactionsTypes.CRYPTO_DEPOSIT]: {
    label: 'Crypto Deposits',
    income: 'positive',
    showOnFixedPrice: false
  },
  [TransactionsTypes.PROMO_CAMPAIGN_REWARD]: {
    label: TransactionsTypeNaming[TransactionsTypes.PROMO_CAMPAIGN_REWARD],
    income: 'positive',
    showOnFixedPrice: true
  },
  [TransactionsTypes.LIQUIDITY_DEPOSIT]: {
    label: TransactionsTypeNaming[TransactionsTypes.LIQUIDITY_DEPOSIT],
    income: 'positive',
    showOnFixedPrice: false
  },
  [TransactionsTypes.DISTRIBUTION]: {
    label: TransactionsTypeNaming[TransactionsTypes.DISTRIBUTION],
    income: 'positive',
    showOnFixedPrice: true
  },
  [TransactionsTypes.LIQUIDITY_DEPOSIT_ROLLBACK]: {
    label:
      TransactionsTypeNaming[TransactionsTypes.LIQUIDITY_DEPOSIT_ROLLBACK],
    income: 'negative',
    showOnFixedPrice: false
  },
  [TransactionsTypes.LIQUIDITY_WITHDRAW]: {
    label: 'Liquidity Withdrawal',
    income: 'negative',
    showOnFixedPrice: false
  },
  [TransactionsTypes.CRYPTO_WITHDRAW]: {
    label: 'Crypto Withdrawals ',
    income: 'negative',
    showOnFixedPrice: false
  },
  [TransactionsTypes.TRANSFER_OUT]: {
    label: TransactionsTypeNaming[TransactionsTypes.TRANSFER_OUT],
    income: 'negative',
    showOnFixedPrice: true
  },
  [TransactionsTypes.BURN]: {
    label: TransactionsTypeNaming[TransactionsTypes.BURN],
    income: 'negative',
    showOnFixedPrice: true
  },
  [TransactionsTypes.WITHDRAW]: {
    label: 'Withdrawals',
    income: 'negative',
    showOnFixedPrice: true
  },
  [TransactionsTypes.LOYALTY_SWAP]: {
    label: TransactionsTypeNaming[TransactionsTypes.LOYALTY_SWAP],
    income: 'negative',
    showOnFixedPrice: true
  }
};

const defaultDateData = {
  from: DateTime.now()
    .minus({ day: 1 })
    .startOf('month')
    .toFormat('yyyy-LL-dd'),
  to: DateTime.now().minus({ day: 1 }).toFormat('yyyy-LL-dd')
};

export default {
  name: 'OperatorBalanceMovementReport',
  components: {
    VExtendedDataPicker,
    Tooltip
  },
  data(): any {
    return {
      isLoading: false,
      isCSVLoading: false,
      dates: { ...defaultDateData },
      balanceData: null as OperatorBalanceOverallData | null
    };
  },
  computed: {
    ...mapState('TokensInfo', ['tokenPrice']),
    ...mapState('OperatorPayments', ['balance']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapGetters('ClientsInfo', ['isListed']),

    headers(): any[] {
      return [
        { text: 'Operation Type', value: 'type', width: '20%' },
        { text: 'Released Tokens, USDT', value: 'amount', width: '20%' },
        {
          text: `Released Tokens, ${this.tokenPrice.currency}`,
          value: 'tokenAmount',
          width: '20%'
        },
        { text: 'Commission Fee, USDT', value: 'fee', width: '20%' },
        {
          text: `Commission Fee, ${this.tokenPrice.currency}`,
          value: 'tokenFee',
          width: '20%'
        }
      ];
    },

    loyaltySwapFeeActive(): boolean {
      return this.balance.loyaltySwapFeeActive;
    },

    hasDistributionType(): boolean {
      return this.balanceData?.fees.find(
        ({ type }) => type === TransactionsTypes.DISTRIBUTION
      );
    },

    operationKeys(): string[] {
      const hideOptions = {
        [TransactionsTypes.LOYALTY_SWAP]: !this.loyaltySwapFeeActive,
        [TransactionsTypes.DISTRIBUTION]: !this.hasDistributionType
      };

      return Object.keys(operationMapConfig).filter(
        (key) => !hideOptions[key]
      );
    },

    balanceFees(): FeeItem[] {
      const defaultData = this.operationKeys.reduce((acc, item: string) => {
        if (operationMapConfig[item].showOnFixedPrice || this.isListed) {
          acc[item] = {
            ...operationMapConfig[item],
            fee: 0,
            tokenFee: 0,
            amount: 0,
            tokenAmount: 0
          };
        }
        return acc;
      }, {});

      this.balanceData.fees.forEach(
        (feeItem: OperatorBalanceOverallFeesItem) => {
          if (feeItem.type === TransactionsTypes.BETTING_ROLLBACK) return;

          const feeObj = {
            ...defaultData[feeItem.type],
            ...feeItem
          };

          if (feeItem.type in defaultData) {
            defaultData[feeItem.type] = { ...feeObj };
          }
        }
      );

      return Object.values(defaultData);
    },
    totalTooltipText(): string {
      return this.isListed
        ? 'SUM of Operation Types - (Withdrawal Fee + Crypto Withdrawal Fee)'
        : 'SUM of Operation Types - Withdrawal Fee';
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.loadData();
        }
      },
      immediate: true
    },

    loyaltySwapFeeActive(): void {
      if (this.isLoading) return;

      this.loadData();
    }
  },
  methods: {
    onDateChange(value: string[]): void {
      this.dates = value;
      this.loadData();
    },
    async loadData(): Promise<void> {
      try {
        const { from, to } = this.dates;
        this.isLoading = true;
        this.balanceData = await getOperatorBalanceMovementReport(from, to);
      } catch (e) {
        console.error(e);
        errorToastMessage(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getReport(): Promise<void> {
      const { from, to } = this.dates;
      const selectedDateRange: string =
        from === to
          ? formatDate(from, 'dd MMM')
          : `${formatDate(from, 'dd MMM')} - ${formatDate(to, 'dd MMM')}`;

      try {
        this.isCSVLoading = true;
        const res = await getOperatorBalanceMovementReportCsv(from, to);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        doDownload(url, `movement-report(${selectedDateRange}).csv`);
      } catch (e) {
        console.error(e);
        errorToastMessage(e);
      } finally {
        this.isCSVLoading = false;
      }
    },
    formatToCurrency(value: number, format: string = '0,0.[00]'): string {
      return this.$options.filters.numeralSpaces(value, format);
    }
  }
};
