
















































import { mapGetters, mapState } from 'vuex';
import OperatorListingStatus from '@/views/OperatorBalanceView/OperatorListingStatus.vue';
import OperatorPaymentsCurrentBalance from '@/views/OperatorBalanceView/OperatorPaymentsCurrentBalance.vue';
import OperatorPaymentsSettings from '@/views/OperatorBalanceView/OperatorPaymentsSettings.vue';
import OperatorBalanceInvoices from '@/views/OperatorBalanceView/OperatorBalanceInvoices.vue';

import OperatorPaymentsThreshold from '@/views/OperatorBalanceView/OperatorPaymentsThreshold.vue';
import OperatorBalanceMovementReport from '@/views/OperatorBalanceView/OperatorBalanceMovementReport.vue';
import VTabsHash from '@/components/VTabsHash.vue';

export default {
  name: 'OperatorPaymentsView',
  components: {
    OperatorPaymentsSettings,
    OperatorPaymentsCurrentBalance,
    OperatorPaymentsThreshold,
    OperatorListingStatus,
    OperatorBalanceInvoices,
    OperatorBalanceMovementReport,
    VTabsHash
  },
  data(): any {
    return {
      currentTab: 0
    };
  },
  computed: {
    ...mapGetters('ClientsInfo', ['isListed']),
    ...mapState('ClientsInfo', ['loadingInfo']),
    ...mapState('app', ['isSuperAdmin']),
    tabs(): any[] {
      const arrTabs = [
        {
          id: 'movement-report',
          name: 'Movement Report',
          component: 'OperatorBalanceMovementReport',
          isShow: true
        },
        {
          id: 'invoices',
          name: 'Invoices',
          component: 'OperatorBalanceInvoices',
          isShow: true
        }
      ];

      return arrTabs.filter((tab) => tab.isShow);
    }
  }
};
