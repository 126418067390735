






















































































import { mapActions, mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { ConfirmationDialogData, OperatorBalance } from '@/api/schema';
import { fromPercents, toPercents } from '@/helpers/decimalsHelpers';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'OperatorPaymentsSettings',
  components: {
    ConfirmationDialog
  },
  data(): any {
    return {
      confirmationDialogState: false,
      confirmationDialogData: {
        header: 'Are you sure to update Operator Fee?',
        okText: 'Save'
      } as ConfirmationDialogData,
      formData: {},
      formIsValid: false,
      rules: [
        (v) => vr.required(v),
        (v) => vr.validNumber(v),
        (v) => vr.lte(v, 100)
      ]
    };
  },
  watch: {
    balance: {
      handler(newVal: OperatorBalance): void {
        this.formData = { ...newVal };
        this.$refs.form?.resetValidation();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState('OperatorPayments', ['loading', 'balance']),
    isChangedValues(): boolean {
      const changedCasinoRewardFee =
        this.formData?.casinoRewardFee !== this.balance?.casinoRewardFee ||
        this.formData?.casinoRewardFeeActive !==
          this.balance?.casinoRewardFeeActive;

      const changedLoyaltySwapFee =
        this.formData?.loyaltySwapFee !== this.balance?.loyaltySwapFee ||
        this.formData?.loyaltySwapFeeActive !==
          this.balance?.loyaltySwapFeeActive;

      const changedP2EFee =
        this.formData?.p2eFee !== this.balance?.p2eFee ||
        this.formData?.p2eActive !== this.balance?.p2eActive;

      const changedH2EFee =
        this.formData?.h2eFee !== this.balance?.h2eFee ||
        this.formData?.h2eActive !== this.balance?.h2eActive;

      return (
        changedCasinoRewardFee ||
        changedLoyaltySwapFee ||
        changedP2EFee ||
        changedH2EFee
      );
    },
    casinoRewardFee: {
      get(): number {
        return toPercents(this.formData.casinoRewardFee);
      },
      set(value: number): void {
        this.formData.casinoRewardFee = fromPercents(value);
      }
    },
    loyaltySwapFee: {
      get(): number {
        return toPercents(this.formData.loyaltySwapFee);
      },
      set(value: number): void {
        this.formData.loyaltySwapFee = fromPercents(value);
      }
    },
    p2eFee: {
      get(): number {
        return toPercents(this.formData.p2eFee);
      },
      set(value: number): void {
        this.formData.p2eFee = fromPercents(value);
      }
    },
    h2eFee: {
      get(): number {
        return toPercents(this.formData.h2eFee);
      },
      set(value: number): void {
        this.formData.h2eFee = fromPercents(value);
      }
    }
  },
  methods: {
    ...mapActions('OperatorPayments', ['saveOperatorBalance']),
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.saveOperatorBalance({
            ...this.balance,
            ...this.formData
          })
            .then(() => {
              this.$toast.success('Operator Fee has been updated');
            })
            .catch((err: AxiosError) => {
              errorToastMessage(err);
            })
            .finally((): void => {
              this.confirmationDialogState = false;
            });
        }
      });
    }
  }
};
