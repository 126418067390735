
















































import { mapActions, mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import type { OperatorBalance } from '@/api/schema';

export default {
  name: 'OperatorPaymentsThreshold',
  data(): any {
    return {
      formData: {
        thresholdNotificationLevel: null
      },
      formIsValid: true
    };
  },
  watch: {
    balance: {
      handler(newVal: OperatorBalance): void {
        this.formData = { ...newVal };
        this.$refs.form?.resetValidation();
      },
      deep: true,
      immediate: true
    },
    formData: {
      handler(): void {
        this.$nextTick((): void => {
          this.$refs.form?.validate();
        });
      },
      deep: true
    }
  },
  computed: {
    ...mapState('OperatorPayments', ['loading', 'balance']),
    disableSubmit(): boolean {
      return !this.formIsValid || this.loading.balance;
    },
    notificationRules(): any {
      return [vr.required, (v: string) => vr.validNumber(v)];
    }
  },
  methods: {
    ...mapActions('OperatorPayments', ['saveOperatorBalance']),
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.saveOperatorBalance({ ...this.balance, ...this.formData });
        }
      });
    }
  }
};
