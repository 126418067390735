






























import { mapGetters, mapState } from 'vuex';

export default {
  name: 'OperatorBalanceTokenListedMark',
  computed: {
    ...mapGetters('ClientsInfo', ['isListed']),
    ...mapState('ClientsInfo', ['clientsInfo'])
  }
};
